import React from "react";

export default function Project(props){
    return (
        <div className="flex flex-row justify-evenly items-start">
            {/* <div className="flex flex-col pr-2">
                <div className="">
                    <h3 className={"text-xl " + props.titleClass }>{props.title}</h3>
                </div>
                <div className="flex flex-col">
                    <h4 className={"pl-2 pt-[0.7rem]  " + props.detailsClass}>Details</h4>
                    <div className="flex flex-col pl-6 space-y-[0.7rem] pt-4">
                        <p>Cost: {props.cost}</p>
                        <p>Time: {props.time}</p>
                        <p>Role: {props.role}</p>
                        {/* <p>Description: {props.description}</p> 
                    </div>
                </div>
            </div> */}
            <div>
                <img src={props.link} className={props.imgClass}></img>
            </div>
        </div>
    )
}