import React, {useState, useEffect} from "react";
import Project from "./Project";
import * as app from "../App"

var interval;

export default function SlideShow(props) {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        interval = setInterval(
            () =>
            setIndex((prevIndex) =>
                prevIndex === props.list.length - 1 ? 0 : prevIndex + 1
            ),
            [parseInt(props.speed)]
        );

        return () => {};
    }, [index]);

    return (
        <div className={"slides overflow-hidden ml-[10%] " + props.wrapperClass}>
            <div className="slide-window whitespace-nowrap ease-in-out duration-1000" style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
                {props.list.map((item, index) => {
                    return (<div className="inline-block ml-4" key={index}>
                        <Project key={index} link={item} imgClass="w-56 h-40 cover inline sm:hidden md:inline" titleClass={" w-min whitespace-nowrap px-1 bg-[#ffe686] "}/>
                    </div>)
                })}
            </div>
        </div>
    )
}