import React from "react";
import Title from "./Title";

function Spacer(props) {
  return (
    <div className={props.wrapperClass}>
      <Title extraClass={"spacer " + props.extraClass} text={props.text} />
    </div>
  );
}

export default Spacer;
