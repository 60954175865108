import React from "react";
import Title from "./Title";
import Paragraph from "./Paragraph";

function leftText(props) {
  return (
    <div className={"flex flex-row " + props.contentClass}>
      <div className={"" + props.textWrapperClass}>
        <Title
          text={props.title}
          extraClass={"content-title " + props.titleClass}
        />
        <Paragraph
          text={"Contact for professional contracting services. "}
          extraClass={"content-text " + props.paraClass}
        />
        {}
        <Paragraph text="Available across South Uist, Eriskay, Benbecula, North Uist and Berneray." extraClass={"content-text pt-0.5 " + props.paraClass} />
        { props.extra ? props.extra : null}
      </div>
      
      <img
        src={props.link}
        className={"rounded " + props.imgClass}
        alt="alt"
      />
    </div>
  );
}

export default leftText;
