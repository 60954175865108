import React, { useState } from "react";
import Title from './Title'

export default function TextWithHoverImg (props){
    const [show, setShow] = useState(false)
    
    return (
        <div className={"flex flex-col justify-center items-center" + props.contentClass} onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}>
           <img
            src={props.link}
            // onMouseEnter={() => setShow(true)}
            // onMouseLeave={() => setShow(false)}
            // onMouseOver={() => setShow(true)}
            className={"rounded object-cover " + props.imgClass}
            alt="alt"
          /> 
          <div className={"content-text-wrapper " + props.textWrapperClass} >
            <Title
            //   onMouseEnter={() => setShow(true)}
            //   onMouseLeave={() => setShow(false)}
              text={props.title}
              extraClass={"content-title " + props.titleClass + props.titleBonusClass}
            />
          </div>
        
        </div>
      );
}