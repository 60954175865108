import React from "react";

export default function About (props){
    return (
        <div className="flex flex-col w-4/5 mb-6">
            <h2 className="text-2xl font-semibold display-heavy mb-2 ml-6">About</h2>
            <p>
                A local, professional contractor with years of experience. Contracting available across South Uist, Eriskay, Benbecula, North Uist and Berneray. Reputation for being reliable, responsible and completing work to an excellent standard. 
            </p>
        </div>
    )
}