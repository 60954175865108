import React, {useRef, useState, useEffect, useMemo} from "react";
import Title from "./Title";
import Spacer from "./Spacer";
import useWindowDimensions from "./useWindowDimensions";

function Header(props) {
   const { height, width } = useWindowDimensions();
  return (
    <header className="overflow-hidden bg-green-700 ">
      <div className={props.headerClass}>
        <div className={props.textWrapperClass}>
          <Title text="CMI Contracting" extraClass={props.titleClass} />
          <div className="relitive w-screen ">
      <div className={"line  rotate-45 spin-right duration-1000 bg-white" } ></div>
      <div className={"line  rotate-[-45deg] spin-left duration-1000 bg-white"}></div>
      </div>
            { width < 610 ? 
            <div className="static w-max">
              <p className="top-[50%] left-[12%] right-[50%] w-min center whitespace-nowrap text-xl display absolute">Benbecula</p>
              <p className="absolute top-[25%] left-[50%] right-[50%] w-min center whitespace-nowrap text-2xl display">South Uist</p>
              <p className="absolute top-[75%] left-[50%] right-[50%] w-min center whitespace-nowrap text-2xl display">North Uist</p>
              <p className="top-[50%] left-[85%] right-[50%] w-min center whitespace-nowrap text-xl display absolute">Eriskay</p>
            </div>
            :
            <div className="static w-max">
              <p className="top-[50%] left-[25%] right-[50%] w-min center whitespace-nowrap text-2xl display absolute">Eriskay</p>
              <p className="absolute top-[25%] left-[50%] right-[50%] w-min center whitespace-nowrap text-2xl display">South Uist</p>
              <p className="absolute top-[75%] left-[50%] right-[50%] w-min center whitespace-nowrap text-2xl display">North Uist</p>
              <p className="top-[50%] left-[75%] right-[50%] w-min center whitespace-nowrap text-2xl display absolute">Benbecula</p>
            </div>
            }
          <Spacer text="Contracting across Uist" extraClass={props.subtitleClass} />
        </div>
      </div>
      <div className="relitive w-screen  top-level">
      {/* <div className={"line very-back rotate-45 spin-right duration-1000" } ></div>
      <div className={"line very-back rotate-[-45deg] spin-left duration-1000"}></div> */}
        <p className="absolute top-[60%] left-4 rotate-90 w-1 whitespace-nowrap font-semibold  top-level">Phone +44 7824 506 804</p>
        <p className="absolute top-[20%] right-4 rotate-90 w-1 whitespace-nowrap font-semibold  top-level">Phone +44 7824 506 804</p>
        <p className="absolute top-2 left-[20%] w-1 whitespace-nowrap font-semibold top-level">Phone +44 7824 506 804</p>
        <p className="absolute bottom-4 left-[30%] w-1 whitespace-nowrap font-semibold top-level">Email: c_macinnes@outlook.com</p>
        <p className="absolute top-8 left-[70%] w-1 whitespace-nowrap text-xl font-semibold top-level">Email: c_macinnes@outlook.com</p>
        
      </div>
    </header>
  );
}

export default Header;
