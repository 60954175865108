import Header from './components/Header';
import './App.css';
import './styles.css'
import ImgSpacer from './components/ImgSpacer copy';
import Spacer from './components/Spacer';
import PopUp from './components/PopUp'
import RightComponent from './components/RightComponent'
import Contact from './components/Contact';
import useWindowDimensions from './components/useWindowDimensions';
import TextWithHoverImg from './components/TextWithHoverImg';
import Project from './components/Project';
import SlideShow from './components/SlideShow';
import About from './About';

import sparkingSaw from './imgs/sparking_saw_cut2.jpg'
import digger from './imgs/IMG-20220710-WA0002.jpg'
import cattleGrid from './imgs/IMG-20220704-WA0008.jpg'
import ditch from './imgs/IMG-20220704-WA0004.jpg'
import concreting from './imgs/IMG-20220704-WA0000.jpg'
import digger2 from './imgs/IMG-20220704-WA0002.jpg'
import cattleGrid2 from './imgs/IMG-20220704-WA0001.jpg'

const projects = [{'name': 'Cattle Grid 17', 'cost': '£ 2,000', 'time': '9 days', 'role':'Main Contractor', 'link':digger},
                  {"name":'Fence 34', 'cost': '£ 4,000', 'time': '1 Week', 'role':'Main Contractor', 'link':cattleGrid}, 
                  {"name":'Concreting 25', 'cost': '£ 8,000', 'time': '2 Weeks', 'role':'Main Contractor', 'link':ditch}]

const extendedProjects = [digger, cattleGrid, cattleGrid2, ditch, concreting, digger2]


const mobileRow1Projects = extendedProjects
const mobileRow2Projects = JSON.parse(JSON.stringify(extendedProjects)).reverse()

const operationsInfoList = [
                            "Fencing", "Concreting", "Ditching", "Roads ", 
                            "Foundations","Landscaping", "Land drainage ",
                            "Machinery hire (with operator)", "Cattle grids",   
                          ]
const yellowOperations = ["Fencing", "Ditching", "Machinery hire (with operator)"]
const minimisedOperationsInfoList = [
  "Fencing", "Concreting", "Landscaping", "Roads", 
  "Foundations","Ditching",
  "Machinery hire (with operator)", "Cattle grids",   
]

const contactUs = "Contact for professional contracting services available across South Uist, Eriskay, Benbecula, and North Uist."

const lightYellow = " bg-[#ffe686] "
// const heavyYellow = " bg-[#feda4e] "
const heavyYellow = " decoration-[#fc0]  "

function App() {

  const year = new Date().getFullYear();

  const extraContactCode = <div className='flex flex-col space-y-14 h-52 justify-center pt-4 display-heavy'><p>Phone: +44 7824 506 804</p><p>Email: c_macinnes@outlook.com</p></div>
  
  const { height, width } = useWindowDimensions();

  return (
    <div className="App display-heavy overflow-hidden">
      {/* <PopUp /> */}
      <Header titleClass=" text-3xl sm:text-4xl tracking-normal md:tracking-wider top-level" 
      subtitleClass="text-2xl top-level relative" textWrapperClass="flex items-center justify-center flex-col space-y-1 top-level" headerClass="flex items-center justify-center h-screen" />

      <div className='bg-white pt-12 top back relative'>
        <Spacer text="Operations" extraClass={"text-2xl px-1 front underline underline-[#fc0] decoration-2 " + heavyYellow} wrapperClass="flex justify-center mb-4  decoration-2"/>
        <div className='p-8 grid grid-cols-2 md:grid-cols-3 gap-6'>
          {width > 640 ? operationsInfoList.map((item, index)=>{
            return (
              <TextWithHoverImg 
                // link={item.image}
                title={item}
                key={index}
                text={""}
                contentClass="items-center flex justify-center items-center front"
                imgClass="sm-landscape hidden"
                paraClass="font-serif display" 
                titleClass={"text-l sm:text-xl font-semibold px-1 display hover:tracking-widest ease-in-out duration-75 "}
                titleBonusClass = { yellowOperations.includes(item) ? "" : null}
              />
            )}) : minimisedOperationsInfoList.map((item, index)=>{
              return (
                <TextWithHoverImg 
                  // link={item.image}
                  title={item}
                  key={index}
                  text={""}
                  contentClass="items-center front"
                  textWrapperClass="flex justify-center items-center w-40"
                  imgClass="vsm-landscape hidden"
                  paraClass="font-serif display" 
                  titleClass="text-xl md:text-xl font-semibold pl-3 display"
                />
              )
          })}
        </div> 
      </div>
      
      <div className='flex flex-col mt-12'>
        <Spacer text="Contracting in South Uist, contracting in Eriskay, Contracting in Benbecula, Contracting in North Uist" extraClass="text-white very-back absolute" />
        <Spacer text="Projects" extraClass={"text-2xl px-1 underline underline-[#fc0] decoration-2 " + heavyYellow } wrapperClass="flex justify-center mt-1 mb-8 "  />
          
        {width < 640 ? 
        <div className='flex flex-col justify-center space-y-8 '>
          <SlideShow list={mobileRow1Projects} speed="3500" wrapperClass='w-[315px]' />
          <SlideShow list={mobileRow2Projects} speed="3500" wrapperClass='w-[315px]' />
        </div>
        : 
        <div className='flex flex-row justify-evenly space-x-2 '> 
          {extendedProjects.map((item, index) => {
            console.log(item)
            return <Project key={index} imgClass="w-56 h-40 cover inline sm:hidden md:inline" link={item}/>
          })}
          {/* <SlideShow list={extendedProjects} speed="3500" wrapperClass='w-5/6' /> */}
        </div>
        }
          
      </div>
      <div className="relative w-screen mt-6">
        <div className={"grey-line back rotate-45 spin-right duration-1000" } ></div>
        <div className={"grey-line back rotate-[-45deg] spin-left duration-1000"}></div>
      </div>
      <Spacer text="Cameron MacInnes Contracting CMI Contracting " extraClass="text-white very-back relative " />
      <div className='flex flex-col width-screen justify-center items-center'>
        <Spacer text="Details" extraClass={"text-2xl px-1 underline underline-[#fc0] decoration-2 " + heavyYellow} wrapperClass="flex justify-center mb-4"/>
        <About />
        <Contact
          className=""
          link={sparkingSaw}
          title="Contact"
          text={contactUs}
          imgClass="lg-portrait hidden sm:inline"
          contentClass="mt-6"
          textWrapperClass=""
          paraClass="w-72 font-serif display-heavy pr-1" 
          titleClass="text-2xl font-semibold pl-6 pr-6 display mb-2"
          extra = {extraContactCode}
        />
      </div>
      <Spacer text={"© " + year + " Jack Edmondson & JEE Websites"} extraClass="" wrapperClass="flex justify-center mt-8 mb-2 text-sm"/>
    </div>
  ); 
}

export default App;
